.whole-container {
    margin: 30px;
}

.whole-container-down {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.contact-container {
    flex-grow: 2;
}

.info-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
}

.contactForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 30px;
}

.checkbox-container {
    font-size: 18px;
    display: flex;
    align-items: center;
}

.checkbox-input {
    width: 18px;
    height: 18px;
}

.privacy-policy-contact {
    text-decoration: none;
}

.photo-container-text {
    text-align: center;
    text-transform: uppercase;
}

.telephone {
    text-decoration: none;
}

.form-container {
    padding: 0;
    margin: 0;
}

.photo-container-text img {
    width: 200px;
    object-fit: contain;
    border-radius: 30px;
    border: 1px solid;
}

.team-container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.team-specifications {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10%;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.team-emails {
    font-size: small;
}

@media screen and (max-width: 800px) {
    .team-specifications {
        flex-direction: column;
    }

    .photo-container-text img {
        width: 260px;
    }
}

@media screen and (max-width: 739px) {
    .whole-container {
        margin: 20px;
    }

    .whole-container-down {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
    }

    .info-container {
        margin: 20px;
        padding-left: 0px;
    }

    .contactForm {
        padding-left: 00px;
    }
}
