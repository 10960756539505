.all {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.input {
    display: none;
}

.products-container {
    width: 100%;
    text-align: center;
    margin-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1700px;
}

.heading-1 {
    font-weight: normal;
    font-size: 35px;
    position: relative;
    margin: 40px 0;
}

.top-content {
    background-color: #4c453c;
    width: 90%;
    margin: 0 auto 20px auto;
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.heading-3 {
    height: 100%;
    background-color: #c2b59b;
    line-height: 60px;
    padding: 0 50px;
    color: white;
}

.filter-btn {
    border: 0;
    display: inline-block;
    height: 100%;
    padding: 0 5px;
    margin: 0 20px;
    line-height: 60px;
    font-size: 18px;
    background-color: transparent;
    color: white;
    cursor: pointer;
    transition: 0.5s;
}

label:hover {
    color: black;
}

.photo-gallery {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.pic {
    position: relative;
    height: 230px;
    margin-bottom: 50px;
}

.picture {
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.picture:hover {
    transform: scale(1.1);
    transition: 0.3s all ease-in-out;
}

@media screen and (max-width: 1024px) {
    .photo-gallery {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
    }

    .products-container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (max-width: 750px) {
    .photo-gallery {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
}

@media screen and (max-width: 520px) {
    .heading-3 {
        display: none;
    }
    .photo-gallery {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }
}
