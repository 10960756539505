.error-container {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.error-container h1 {
    text-align: center;
    margin-top: 0;
}

.embeded-error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.embeded-error-container h4 {
    margin-bottom: 0;
}
