.each-slide {
    display: flex;
    width: 100%;
    height: auto;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
}

.each-slide > div {
    width: 100%;
}

.each-slide > div img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}
