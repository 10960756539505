.home__hero-section {
    width: 100%;
    padding: 80px 0;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
}

.home__hero-row {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
}

.home__hero-row-reverse {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
}

.col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
    margin-left: auto;
    margin-right: auto;
}

.home__hero-text-wrapper {
    text-align: center;
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
}

.top-line {
    color: #f00946;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.heading {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #1c2237;
}

.dark {
    color: #1c2237 !important;
}

.home__hero-subtitle {
    margin-left: auto;
    margin-right: auto;
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: #1c2237;
}

.home__hero-img-wrapper {
    max-width: 555px;
    margin-left: auto;
    margin-right: auto;
}

.home__hero-img {
    max-width: 95%;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    border: 0;
}

.home__hero-img-wrapper {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 984px) {
    .home__hero-section {
        background: rgb(194, 181, 155);
        background: linear-gradient(
            180deg,
            rgba(194, 181, 155, 1) 0%,
            rgba(76, 69, 60, 1) 85%
        );
    }

    .darkBg {
        background: rgb(76, 69, 60);
        background: linear-gradient(
            180deg,
            rgba(76, 69, 60, 1) 25%,
            rgba(193, 181, 157, 1) 100%
        );
    }

    .heading {
        color: white;
    }

    .home__hero-subtitle {
        color: white;
    }

    .home__hero-row {
        flex-direction: column;
    }

    .home__hero-row-reverse {
        flex-direction: column;
    }
    .container {
        padding-right: 30px;
        padding-left: 30px;
    }
}

@media screen and (max-width: 768px) {
    .home__hero-text-wrapper {
        padding-bottom: 65px;
    }

    .col {
        max-width: 100%;
        flex-basis: 100%;
    }
}
