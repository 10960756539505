.navbar {
    background: #ffffff;
    height: auto;
    text-transform: uppercase;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar-container {
    margin: 0 auto;
    padding: 0px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1700px;
    z-index: 1;
}

.nav-menu {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0px;
}

.nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 20px 9px 20px;
}

.nav-item-contact {
    display: none;
}

.nav-links {
    text-decoration: none;
    color: #6d6861;
}

.nav-links:hover {
    color: #4c453d;
    transition: 0.5s;
}

.btn--primary:hover {
    color: #cac4bc;
    transition: 0.5s;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 984px) {
    .nav-menu {
        display: none;
    }

    .contact {
        display: none;
    }

    .active {
        position: fixed;
        top: 45px;
        left: 0;
        height: 70%;
        background-color: #ffffff;
        width: 100%;
        display: flex;
        gap: 1.5rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .nav-item-contact {
        display: flex;
        margin-top: 15px;
        padding: 12px 25px;
        width: 20rem;
        cursor: pointer;
        text-transform: uppercase;
        background-color: #1a1b1f;
        color: white;
        justify-content: center;
        align-items: center;
    }

    .nav-link-contact {
        text-decoration: none;
        list-style: none;
        color: white;
    }

    .menu-icon {
        display: block;
        position: relative;
        cursor: pointer;
        font-size: 3rem;
    }
}
