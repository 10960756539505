.btn {
    padding: 12px 25px;
    cursor: pointer;
    text-transform: uppercase;
}

.btn--primary {
    background-color: #1a1b1f;
    color: white;
}

.btn--primary :hover {
    color: red;
    transition: 0.5s;
}
