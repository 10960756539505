.footer-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    background-image: url("https://i.imgur.com/X9WRLuC.png");
}

.footer-text-container {
    text-align: center;
}

.text-h1 {
    padding: 24px;
    color: #c2b59b;
    font-size: 1.5rem;
    margin-top: 0;
}

.text-p-link {
    color: #c2b59b;
    text-decoration: underline;
}

.social-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}

.social-icon-link {
    font-size: 35px;
    color: #c2b59b;
}

.website-rights {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    color: #e6dcc9;
    font-size: 12px;
}

.privacy-policy {
    color: #c2b59b;
}

.privacy-policy-link {
    color: #c2b59b;
    text-decoration: underline;
}

@media screen and (max-width: 985px) {
    .footer-text-container {
        text-align: start;
    }

    .text-p-link {
        padding-left: 30px;
    }

    .social-icons {
        justify-content: start;
        gap: 3rem;
        padding-left: 80px;
    }

    .privacy-policy {
        padding-left: 30px;
    }
}

@media screen and (max-width: 778px) {
    .footer-text-container {
        width: 70%;
    }
}

@media screen and (max-width: 525px) {
    .footer-text-container {
        width: 100%;
        text-align: center;
    }

    .footer-container {
        height: 250px;
        background-position: left;
    }

    .text-h1 {
        font-size: 20px;
        margin: 0;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 0px;
    }

    .text-p-link {
        padding: 0;
    }

    .social-icons {
        justify-content: center;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .privacy-policy {
        padding-left: 0;
    }
}
